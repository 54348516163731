<template>
  <v-main>
    <v-card>
      <v-toolbar color="#FB9300" dark flat>
        <v-toolbar-title>List Paket</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon @click="openDialog(`addData`, form)">mdi-plus-circle</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab @click="test(item)" v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table
            dense
            :headers="headers"
            :items="internet"
            item-key="name"
            class="elevation-1"
          >
            <template v-slot:[`item.city`]="{ item }">
              <h6>
                <span>{{ item.city }}</span>
              </h6>
            </template>

            <template v-slot:[`item.active`]="{ item }">
              <v-chip
                v-if="item.active == true"
                class="ma-2"
                color="green"
                text-color="white"
              >
                Aktif
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                Tidak Aktif
              </v-chip>
            </template>

            <template v-slot:[`item.timestamp`]="{ item }">
              <h6>
                <span>{{ item.timestamp.toDate().toDateString() }}</span>
              </h6>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn color="yellow darken-2" fab small dark>
                <v-icon @click="openDialog(`updateData`, item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>

              <v-btn class="ml-2" color="error" fab small dark>
                <v-icon @click="alertChecker(`menghapus`, item.id)"
                  >mdi-trash-can</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            dense
            :headers="headersCCTV"
            :items="cctv"
            item-key="name"
            class="elevation-1"
          >
            <template v-slot:[`item.data`]="{ item }">
              <h6>
                <span>{{ item.data[8] }} MP Camera </span>
                <br />
                <span>{{ item.data[0] }} Channel </span>
                <br />
                <span> {{ item.data[2] }} Unit DVR {{ item.data[1] }} </span>
                <br />
                <span>{{ item.data[4] }} Unit Kamera Indoor </span>
                <br />
                <span>{{ item.data[3] }} Unit Kamera Outdoor </span>
                <br />
                <span>{{ item.data[5] }} Unit HDD WD 1TB </span>
                <br />
                <span>{{ item.data[6] }} Unit Kabel RG59+Power </span>
                <br />
                <span>{{ item.data[7] }} Pcs Power Supply 10A </span>
                <br />
                <span>{{ item.data[0] }} Titik Jasa Instalasi</span>
                <br />
              </h6>
            </template>

            <template v-slot:[`item.city`]="{ item }">
              <h6>
                <span>{{ item.city }}</span>
              </h6>
            </template>

            <template v-slot:[`item.active`]="{ item }">
              <v-chip
                v-if="item.active == true"
                class="ma-2"
                color="green"
                text-color="white"
              >
                Aktif
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                Tidak Aktif
              </v-chip>
            </template>

            <template v-slot:[`item.timestamp`]="{ item }">
              <h6>
                <span>{{ item.timestamp.toDate().toDateString() }}</span>
              </h6>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn color="yellow darken-2" fab small dark>
                <v-icon @click="openDialog(`updateData`, item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>

              <v-btn class="ml-2" color="error" fab small dark>
                <v-icon @click="alertChecker(`menghapus`, item.id)"
                  >mdi-trash-can</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            dense
            :headers="headersTV"
            :items="tv"
            item-key="name"
            class="elevation-1"
          >
            <template v-slot:[`item.city`]="{ item }">
              <h6>
                <span>{{ item.city }}</span>
              </h6>
            </template>

            <template v-slot:[`item.active`]="{ item }">
              <v-chip
                v-if="item.active == true"
                class="ma-2"
                color="green"
                text-color="white"
              >
                Aktif
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                Tidak Aktif
              </v-chip>
            </template>

            <template v-slot:[`item.timestamp`]="{ item }">
              <h6>
                <span>{{ item.timestamp.toDate().toDateString() }}</span>
              </h6>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn color="yellow darken-2" fab small dark>
                <v-icon @click="openDialog(`updateData`, item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>

              <v-btn class="ml-2" color="error" fab small dark>
                <v-icon @click="alertChecker(`menghapus`, item.id)"
                  >mdi-trash-can</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> Tambah Paket {{ activeTab }}</span>
        </v-card-title>

        <v-card-text v-if="activeTab == 'Internet'">
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.title"
                    label="Judul"
                    :rules="nameRules"
                    hint="Inputkan headline paket"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="form.description"
                    outlined
                    name="input-7-4"
                    label="Deskripsi"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.price"
                    label="Harga Paket"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.city"
                    :items="['ketapang', 'sandai', 'semua']"
                    label="Kota*"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="form.speed"
                    label="Kecepatan Internet"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-switch v-model="form.active" label="Aktif?"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>

        <v-card-text v-if="activeTab == 'CCTV'">
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.title"
                    label="Judul"
                    hint="Inputkan headline paket"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.cameraMP"
                    label="Spesifikasi Kamera"
                    hint="Inputkan headline paket"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.price"
                    label="Harga Paket"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.channel"
                    label="Jumlah Channel"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.dvrName"
                    label="Nama Model DVR"
                    hint="Input kecepatan internet"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.dvr"
                    label="Jumlah DVR"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.outdoorCam"
                    label="Jumlah Kamera Outdoor"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.indoorCam"
                    label="Jumlah Kamera Indoor"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.hdd"
                    label="Jumlah HDD"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.rg59Power"
                    label="Jumlah RG59 + Power"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formCctv.powerSupply"
                    label="Jumlah Power Supply"
                    hint="Input kecepatan internet"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model="formCctv.city"
                    :items="['ketapang', 'sandai', 'semua']"
                    label="Kota*"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-switch v-model="formCctv.active" label="Aktif?"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>

        <v-card-text v-if="activeTab == 'TV'">
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.title"
                    label="Judul"
                    hint="Inputkan headline paket"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="form.price"
                    label="Harga"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-switch v-model="form.active" label="Aktif?"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="(dialog = false), reset()">
            Close
          </v-btn>
          <v-btn color="success" @click="checkerData(action, form)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import {
  collection,
  // getDocs,
  updateDoc,
  getFirestore,
  doc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";

import Swal from "sweetalert2";

const db = collection(getFirestore(), "package_list");

import { getAuth } from "firebase/auth";
const auth = getAuth();
const user = auth.currentUser;

export default {
  data() {
    return {
      tab: null,
      profileName: "",
      valid: true,
      items: ["Internet", "CCTV", "TV"],
      activeTab: "Internet",
      internet: [],
      cctv: [],
      tv: [],
      dialog: false,
      // action: "addData",
      form: {
        uid: "",
        title: "",
        city: "",
        speed: 0,
        active: false,
        description: "",
        price: 0,
      },

      formCctv: {
        title: "",
        price: 0,
        channel: 0,
        dvrName: "",
        dvr: 0,
        cameraMP: 0,
        outdoorCam: 0,
        indoorCam: 0,
        hdd: 0,
        rg59Power: 0,
        powerSupply: 0,
        city: "",
        active: false,
      },

      // rules
      nameRules: [
        (v) => !!v || "Name is required",
        // (v) => (v && v.length >= 10) || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      headers: [
        {
          text: "Judul",
          align: "start",
          value: "name",
          width: "10%",
        },
        { text: "Kecepatan", align: "start", value: "speed", width: "1%" },
        { text: "Harga", align: "start", value: "price", width: "3%" },
        { text: "Wilayah", align: "start", value: "city", width: "5%" },
        { text: "Status", align: "start", value: "active", width: "1%" },
        {
          text: "Dibuat Tanggal",
          align: "start",
          value: "timestamp",
          width: "10%",
        },
        { text: "Pengaturan", align: "start", value: "action", width: "5%" },
      ],

      headersCCTV: [
        {
          text: "Judul",
          align: "start",
          value: "name",
          width: "10%",
        },
        { text: "Ket.", align: "start", value: "data", width: "20%" },
        { text: "Wilayah", align: "start", value: "city", width: "5%" },
        { text: "Status", align: "start", value: "active", width: "5%" },
        {
          text: "Dibuat Tanggal",
          align: "start",
          value: "timestamp",
          width: "10%",
        },
        { text: "Pengaturan", align: "start", value: "action", width: "5%" },
      ],

      headersTV: [
        {
          text: "Judul",
          align: "start",
          value: "name",
          width: "20%",
        },
        { text: "Wilayah", align: "start", value: "city", width: "10%" },
        { text: "Harga", align: "start", value: "price", width: "10%" },
        { text: "Status", align: "start", value: "active", width: "10%" },
        {
          text: "Dibuat Tanggal",
          align: "start",
          value: "timestamp",
          width: "15%",
        },
        { text: "Pengaturan", align: "start", value: "action", width: "5%" },
      ],
    };
  },

  methods: {
    realTImeReadData() {
      onSnapshot(db, (snapshot) => {
        let internet = [];
        let cctv = [];
        let tv = [];
        snapshot.docs.forEach((doc) => {
          if (doc.data().package_name == "internet") {
            internet.push({ ...doc.data(), id: doc.id });
          } else if (doc.data().package_name == "cctv") {
            cctv.push({ ...doc.data(), id: doc.id });
          } else if (doc.data().package_name == "tv") {
            tv.push({ ...doc.data(), id: doc.id });
          } else {
            // console.log("error connection");
          }

          this.internet = internet;
          this.cctv = cctv;
          this.tv = tv;
          // console.log("realTImeReadData", this.internet);
        });
      });
    },

    alert(action, condition) {
      Swal.fire({
        icon: condition,
        title: action + " data",
        // text: "Something went wrong!",
        // showConfirmButton: false,
        timer: 3000,
      });
    },

    alertChecker(action, itemID) {
      Swal.fire({
        title: "Yakin " + action + " data ?",
        text: "Jika sudah mengkonfirmasi data tidak bisa dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4CAF50",
        confirmButtonText: "Ya",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batalkan",
      }).then((result) => {
        if (result.isConfirmed) {
          if (action == "menghapus") {
            this.deleteData(itemID);
            Swal.fire({
              icon: "success",
              title: "Sukses",
              text: "Data terpilih berhasil dihapus",
              confirmButtonColor: "#4CAF50",
              timer: 3000,
            });
          }
        }
      });
    },

    openDialog(action, item) {
      if (this.activeTab == "TV" && this.tv.length > 0 && action == "addData") {
        this.alert("Saat ini hanya 1 paket tersedia", "error");
        return;
      } else {
        // console.log(item);
        this.dialog = true;
        this.action = action;
        if (action == "updateData") {
          if (this.activeTab == "Internet") {
            this.form.uid = item.id;
            this.form.title = item.name;
            this.form.city = item.city;
            this.form.speed = item.speed;
            this.form.price = item.price;
            this.form.active = item.active;
            this.form.description = item.description;
            // console.log(this.form.city);
          } else if (this.activeTab == "CCTV") {
            this.formCctv.title = item.name;
            this.form.uid = item.id;
            this.formCctv.price = item.price;
            this.formCctv.channel = item.data[0];
            this.formCctv.dvrName = item.data[1];
            this.formCctv.dvr = item.data[2];
            this.formCctv.outdoorCam = item.data[3];
            this.formCctv.indoorCam = item.data[4];
            this.formCctv.hdd = item.data[5];
            this.formCctv.rg59Power = item.data[6];
            this.formCctv.powerSupply = item.data[7];
            this.formCctv.cameraMP = item.data[8];

            this.formCctv.city = item.city;
            this.formCctv.active = item.active;
          } else if (this.activeTab == "TV") {
            console.log(item);
            this.form.uid = item.id;
            this.form.title = item.name;
            this.form.price = item.price;
            this.form.active = item.active;
          }
        }
      }
    },

    checkerData(action, form) {
      // console.log(form);
      if (action == "addData") {
        this.saveData(form);
      } else {
        this.updateData(form);
      }
      console.log("masuk");
      // this.reset();
    },

    saveData(form) {
      if (this.activeTab == "Internet") {
        if (
          this.form.title == "" ||
          this.form.city == "" ||
          this.form.description == "" ||
          this.form.price == "" ||
          this.form.speed == ""
        ) {
          this.alert("Gagal tambah", "error");
          console.log(this.form);
        } else {
          addDoc(db, {
            active: form.active,
            city: form.city,
            description: form.description,
            name: form.title,
            speed: form.speed,
            price: form.price,
            package_name: this.activeTab.toLowerCase(),
            timestamp: serverTimestamp(),
            updateData: serverTimestamp(),
            createBy: this.profileName,
            updateBy: this.profileName,
          }).then(() => {
            // console.log(result);
            // console.log("myform", this.form);
            this.reset();
            this.dialog = false;
            this.alert("Sukses tambah", "success");
          });
        }
      } else if (this.activeTab == "CCTV") {
        if (
          this.formCctv.title == "" ||
          this.formCctv.price == 0 ||
          this.formCctv.channel == 0 ||
          this.formCctv.dvrName == "" ||
          this.formCctv.dvr == 0 ||
          this.formCctv.outdoorCam == 0 ||
          this.formCctv.indoorCam == 0 ||
          this.formCctv.hdd == 0 ||
          this.formCctv.rg59Power == 0 ||
          this.formCctv.powerSupply == 0 ||
          this.formCctv.cameraMP == 0 ||
          this.formCctv.city == ""
        ) {
          console.log(this.formCctv);
          this.alert("Gagal tambah", "error");
        } else {
          addDoc(db, {
            name: this.formCctv.title,
            price: this.formCctv.price,
            data: [
              this.formCctv.channel,
              this.formCctv.dvrName,
              this.formCctv.dvr,
              this.formCctv.outdoorCam,
              this.formCctv.indoorCam,
              this.formCctv.hdd,
              this.formCctv.rg59Power,
              this.formCctv.powerSupply,
              this.formCctv.cameraMP,
            ],
            active: this.formCctv.active,
            city: this.formCctv.city,
            package_name: this.activeTab.toLowerCase(),
            timestamp: serverTimestamp(),
            updateData: serverTimestamp(),
            createBy: this.profileName,
            updateBy: this.profileName,
          }).then(() => {
            this.reset();
            this.dialog = false;
            this.alert("Sukses tambah", "success");
          });
        }
      } else {
        addDoc(db, {
          active: form.active,
          city: form.city,
          description: form.description,
          name: form.title,
          speed: form.speed,
          package_name: this.activeTab.toLowerCase(),
          timestamp: serverTimestamp(),
          updateData: serverTimestamp(),
          createBy: this.profileName,
          updateBy: this.profileName,
        }).then(() => {
          // console.log(result);
          // console.log("myform", this.form);
          this.reset();
          this.dialog = false;
          this.alert("Sukses tambah", "success");
        });
      }

      // addDoc(db, {
      //   active: form.active,
      //   city: form.city,
      //   description: form.description,
      //   name: form.title,
      //   speed: form.speed,
      //   package_name: this.activeTab.toLowerCase(),
      //   timestamp: serverTimestamp(),
      // }).then(() => {
      //   // console.log(result);
      //   // console.log("myform", this.form);
      //   this.reset();
      //   this.dialog = false;
      //   this.alert("tambah", "success");
      // });
    },

    updateData(form) {
      // console.log("updateData ", form);
      const docRef = doc(getFirestore(), "package_list", form.uid);

      if (this.activeTab == "Internet") {
        if (
          this.form.title == "" ||
          this.form.city == "" ||
          this.form.description == "" ||
          this.form.price == "" ||
          this.form.speed == ""
        ) {
          this.alert("Gagal tambah", "error");
          console.log(this.form);
        } else {
          updateDoc(docRef, {
            name: form.title,
            city: form.city,
            speed: form.speed,
            price: form.price,
            active: form.active,
            description: form.description,
            updateData: serverTimestamp(),
            updateBy: this.profileName,
          })
            .then(() => {
              // console.log("sukses ", result);
              this.alert("Sukses mengubah", "success");
              this.dialog = false;
            })
            .catch((e) => {
              this.alert("Gagal mengubah", "error");
              console.log(e);
              this.dialog = false;
            });
        }
      } else if (this.activeTab == "CCTV") {
        if (
          this.formCctv.title == "" ||
          this.formCctv.price == 0 ||
          this.formCctv.channel == 0 ||
          this.formCctv.dvrName == "" ||
          this.formCctv.dvr == 0 ||
          this.formCctv.outdoorCam == 0 ||
          this.formCctv.indoorCam == 0 ||
          this.formCctv.hdd == 0 ||
          this.formCctv.rg59Power == 0 ||
          this.formCctv.powerSupply == 0 ||
          this.formCctv.cameraMP == 0 ||
          this.formCctv.city == ""
        ) {
          console.log(this.formCctv);
          this.alert("Gagal tambah", "error");
        } else {
          updateDoc(docRef, {
            name: this.formCctv.title,
            price: this.formCctv.price,
            data: [
              this.formCctv.channel,
              this.formCctv.dvrName,
              this.formCctv.dvr,
              this.formCctv.outdoorCam,
              this.formCctv.indoorCam,
              this.formCctv.hdd,
              this.formCctv.rg59Power,
              this.formCctv.powerSupply,
              this.formCctv.cameraMP,
            ],
            active: this.formCctv.active,
            city: this.formCctv.city,
            updateData: serverTimestamp(),
            updateBy: this.profileName,
          })
            .then(() => {
              // console.log("sukses ", result);
              this.alert("Sukses mengubah", "success");
              this.dialog = false;
            })
            .catch((e) => {
              this.alert("Gagal mengubah", "error");
              console.log(e);
              this.dialog = false;
            });
        }
      } else if (this.activeTab == "TV") {
        console.log("test");
        updateDoc(docRef, {
          name: this.form.title,
          price: this.form.price,
          active: this.form.active,
          updateData: serverTimestamp(),
          updateBy: this.profileName,
        })
          .then(() => {
            // console.log("sukses ", result);
            this.alert("Sukses mengubah", "success");
            this.dialog = false;
          })
          .catch((e) => {
            this.alert("Gagal mengubah", "error");
            console.log(e);
            this.dialog = false;
          });
      }
    },

    deleteData(itemID) {
      const docRef = doc(getFirestore(), "package_list", itemID);
      deleteDoc(docRef).then(() => {
        // this.reset();
        // console.log("delete success", result);
      });
    },

    test(item) {
      this.activeTab = item;
      console.log(this.activeTab);
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      // this.form.uid = "";
      // this.form.title = "";
      // this.form.city = "";
      // this.form.speed = 0;
      // this.form.active = false;
      // this.form.description = "";
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },

  mounted() {
    // this.readData();
    this.realTImeReadData();
    if (user !== null) {
      this.profileName = user.email.match(/^([^@]*)@/)[1];
    }
  },
};
</script>

<style></style>
